import $ from "jquery";

const addFieldCoursePlanSectionExercise = () => {
  $("form").on("click", ".add_course_plan_section_exercise_field", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    let selector = `#${$(this).data("parent-id")} .course_plan_section_exercise_fields`;
    $(selector).append($(this).data("fields").replace(regexp, time));
    $(selector).find('.select2').select2();

    $(selector).find("> tr:last > td > input.order_field").each(function(index) {
      $(this).val($(selector).find("> tr").length);
    });

    return event.preventDefault();
  });
};

export { addFieldCoursePlanSectionExercise };
